<template>
  <div class="join done container">
    <div class="baseBox">
      <div class="joinBox reset">
        <h3>비밀번호변경이 <span>완료</span>되었습니다.</h3>

        <div class="buttonWrap">
          <router-link to="/login"><button class="point full">로그인하기</button></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch("SET_MENU_VISIBLE", false);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", false);
  },
};
</script>
